import React, { lazy, Suspense, useState } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { addQuarters, endOfDay, endOfQuarter, startOfQuarter } from "date-fns";
import { useTranslation } from "react-i18next";

import Label from "@components/form/Label";
import Card from "@components/layout/Card";
import Layout from "@components/layout/Layout";
import Loading from "@components/shared/Loading";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import { formatDate } from "@utils/formatDate";
import { getRegulatoryReports } from "@services/reports";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";

type DateRange = {
  from: Date;
  to: Date;
};

const RegulatoryReports: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [renderers, setRenderers] = useState<any>({});
  const [SelectedReport, setSelectedReport] = useState<any>();
  const [reportProps, setReportProps] = useState<
    Partial<{
      report: any;
      fromDate: Date;
      toDate: Date;
      runAt: Date;
      dateRanges: DateRange[];
      level1ResourceId: string;
    }>
  >({});
  const [filter, setFilter] = useState<
    Partial<{
      report: any;
      level1ResourceId: string;
      dateRanges: DateRange[];
    }>
  >({});

  const { data: reports } = useQuery(
    ["reports", "regulatory"],
    getRegulatoryReports,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        const updatedRenderers = { ...renderers };
        for (const report of data) {
          updatedRenderers[report.id] = lazy(
            () => import(`@components/reports/${report.type}`),
          );
        }

        setRenderers(updatedRenderers);
        setSelectedReport(undefined);

        const selectedReport = data.find((r: any) => r.id === id);
        if (selectedReport) {
          setFilter({ ...filter, report: selectedReport });
        }
      },
    },
  );

  const { data: accountingPeriods = [] } = useAllAccountingPeriods({
    params: {
      level1ResourceId: filter.level1ResourceId,
    },
    options: {
      enabled: Boolean(filter.level1ResourceId),
      refetchOnWindowFocus: false,
    },
  });

  const getDateRangeOptions = () => {
    const options: any = [];

    const uniquePeriods: any = {};
    accountingPeriods?.forEach((period: any) => {
      const uniqueKey = `${period.periodStart.toString()}-${period.periodEnd.toString()}`;
      if (!uniquePeriods[uniqueKey]) {
        uniquePeriods[uniqueKey] = period;
      }
    });

    const uniqueAccountingPeriods: any = Object.values(uniquePeriods);

    if (uniqueAccountingPeriods)
      for (const accountingPeriod of uniqueAccountingPeriods) {
        for (let i = 0; i < 4; i++) {
          const qStart = startOfQuarter(
            addQuarters(new Date(accountingPeriod.periodStart), i),
          );
          const qEnd = endOfDay(endOfQuarter(qStart));
          options.push({
            label: `Q${i + 1} (${formatDate(qStart)} - ${formatDate(qEnd)})`,
            value: [
              {
                from: qStart,
                to: qEnd,
              },
            ],
          });
        }

        const yStart = new Date(accountingPeriod.periodStart);
        const yEnd = new Date(accountingPeriod.periodEnd);
        options.push({
          label: `Water Year (${formatDate(yStart)} - ${formatDate(yEnd)})`,
          value: options.map((i: any) => i.value).flat(),
        });
      }

    return options;
  };

  const getReportOptions = () => {
    return reports?.map((report: any) => ({
      label: report.name,
      value: report.id,
      type: report.type,
    }));
  };

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const handleRenderReport = (e: any) => {
    e.preventDefault();

    if (renderers[filter.report?.id]) {
      setSelectedReport(renderers[filter.report?.id]);

      setReportProps({
        report: filter.report,
        fromDate: filter?.dateRanges?.[0]?.from,
        toDate: filter?.dateRanges?.[0]?.to,
        dateRanges: filter?.dateRanges,
        runAt: new Date(),
        level1ResourceId: filter?.level1ResourceId,
      });
    }
  };

  return (
    <Layout
      permissions={["ViewReports"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("reporting.title"),
        },
      ]}
      title={t("reporting.new_regulatory_report")}
    >
      <Card className="flex flex-col rounded-none p-0 grow">
        <div className="flex flex-col grow gap-4 divide-y">
          <form
            className="flex items-end gap-2 p-4"
            onSubmit={handleRenderReport}
          >
            <div className="w-1/4">
              <Label htmlFor="report">{t("reporting.filter_report")}</Label>
              <Select
                inputId="report"
                options={getReportOptions()}
                value={getReportOptions()?.find(
                  (e: any) => e.value === filter.report?.id,
                )}
                onChange={(e: any) => {
                  handleFilterChange(
                    "report",
                    reports?.find((report: any) => report.id === e?.value),
                  );
                }}
                openMenuOnFocus
              />
            </div>
            <div className="w-1/4">
              <Label htmlFor="level1Resource">
                {t("reporting.filter_level1wrs")}
              </Label>
              <SelectLevel1Resource
                inputId="level1Resource"
                value={filter.level1ResourceId}
                onChange={(e: any) => {
                  handleFilterChange("level1ResourceId", e.value);
                }}
              />
            </div>
            <div className="w-1/4">
              <Label htmlFor="dateRange">{t("reporting.filter_dates")}</Label>
              <Select
                inputId="dateRange"
                options={getDateRangeOptions()}
                onChange={(e: any) => {
                  setFilter(prev => ({
                    ...prev,
                    dateRanges: e.value ?? [],
                  }));
                }}
                openMenuOnFocus
              />
            </div>
            <div className="w-1/3 sm:w-1/5">
              <button
                type="submit"
                className="btn-secondary"
                disabled={!filter.report || !filter?.dateRanges?.length}
              >
                {t("reporting.get_data")}
              </button>
            </div>
          </form>
          {SelectedReport && (
            <div className="p-4 flex flex-col grow">
              <Suspense fallback={<Loading />}>
                <SelectedReport {...reportProps} />
              </Suspense>
            </div>
          )}
        </div>
      </Card>
    </Layout>
  );
};

export default RegulatoryReports;
