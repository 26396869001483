import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { checkLogIn as authCheckLogIn, getAuthMode } from "@services/auth";
import TextInput from "../TextInput";
import Label from "../Label";
import Alert from "@components/shared/Alert";
import ENV from "config/env";
import { useResendConfirmSignUpCode } from "@hooks/mutation/zenith/useResendConfirmSignUpCode";
import { toastError, toastSuccess } from "@utils/toast";
import Loading from "@components/shared/Loading";

type LoginFormProps = {
  onLogin: (userData: any) => void;
};

const LoginForm: React.FunctionComponent<LoginFormProps> = ({ onLogin }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  const isAdmin = location.pathname.includes("polestar");
  const { data: authMode, isLoading: isGettingAuthMode } = useQuery(
    ["auth-mode"],
    getAuthMode,
    {
      enabled: isAdmin,
      onSuccess: (data: any) => {
        if (data.mode === "sso") {
          window.location.href = `${ENV.API_HOST}/sso`;
        }
      },
    },
  );
  const {
    mutateAsync: checkLogIn,
    isLoading,
    error,
  } = useMutation<any, any>(() => authCheckLogIn({ email, password, isAdmin }));

  const { mutateAsync: resendMutation, isLoading: isResendLoading } =
    useResendConfirmSignUpCode();

  const handleLoginClick = async (e: any) => {
    e.preventDefault();

    try {
      const { user, ...tokenData } = await checkLogIn();

      if (user.email === ENV.TEST_ACCOUNT) {
        user.tokenData = tokenData;
      }

      const isUnconfirmedZenithUser =
        !user.isAdmin && user.status === "UNCONFIRMED";

      if (isUnconfirmedZenithUser) {
        const email = user?.email;
        try {
          await resendMutation({
            email,
          });
          toastSuccess(t("zenith.confirm_signup.resend.toast.success"));
        } catch (error: any) {
          toastError(
            t("zenith.confirm_signup.resend.toast.login_failure", {
              error: error?.response?.data?.message || error?.message,
            }),
          );
        }

        navigate("/zenith/confirm_signup", {
          state: { email },
        });

        return;
      }

      onLogin({
        password,
        ...user,
        ...(user.isAdmin === false &&
          ENV.BYPASS_MFA &&
          tokenData.token && { tokenData }),
      });
    } catch (error: any) {
      toastError(t(error?.response?.data?.message || "auth.error_login"));
      return;
    }
  };

  if (isAdmin && (isGettingAuthMode || authMode?.mode === "sso"))
    return <Loading loadingText={`${t("auth.loading_sso")}...`} />;

  return (
    <>
      {error && (
        <Alert type="error" className="mb-6">
          {error?.response?.data?.message || t("auth.error_login")}
        </Alert>
      )}

      <form className="space-y-6" onSubmit={handleLoginClick}>
        <div>
          <Label htmlFor="email">{t("user.username")}</Label>
          <TextInput
            type="email"
            id="email"
            required
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div>
          <Label htmlFor="password">{t("user.password")}</Label>
          <TextInput
            type="password"
            id="password"
            required
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm">
            <Link
              to="../forgotPassword"
              className="font-semibold text-primary-3 hover:text-primary-2"
            >
              {t("auth.forgot_password")}
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            className="btn-primary font-semibold w-full"
            disabled={isLoading || isResendLoading}
          >
            {t("auth.login")}
          </button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
